import { createDateTimeZone } from "agcustom/common/AGFunctions";

export default class AGRest {
  static async fetchWithTimeoutJSON(resource,method='GET',token='', timeout=20000, body={}, signal){
      let options={
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Accept-Encoding': 'gzip'
          },
          method:method,
          timeout : timeout
      }

      if(method=='PUT' || method=='POST' || method=='PATCH' || method=="DELETE"){
        options["body"]=JSON.stringify(body)
      }
      if(token != ''){
        let headers = options["headers"]
        headers["Authorization"] = 'Bearer ' + token
      } 
      
      let tmp = await AGRest.fetchWithTimeoutRaw(resource, options, signal)
      let ret ={
        response : tmp,
        data     : await tmp.json()
      }
      return ret;
  }

  static async fetchWithTimeoutOctet(resource,method='GET',token='', timeout=20000, body=null){
    let options={
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Accept-Encoding': 'gzip'
        },
        method:method,
        timeout : timeout
    }

    if(method=='PUT' || method=='POST' || method=='PATCH' || method=="DELETE"){
      options["body"]=body
    }

    if(token != ''){
      let headers = options["headers"]
      headers["Authorization"] = 'Bearer ' + token
    }

    let tmp = await AGRest.fetchWithTimeoutRaw(resource, options);
    let ret ={
      response : tmp,
      data     : await tmp.blob()
    }

    return ret;
  }

  static async fetchWithTimeoutRaw(resource,options, signal) {
    const { timeout = 90000 } = options;
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);

    let tmp_signal = null;
    if(signal == null)
    {
      tmp_signal = controller.signal
    }
    else
    {
      tmp_signal = signal;
    }

    const response = await fetch(resource, {
      ...options,
      signal: tmp_signal
    });

    clearTimeout(id);
    return response;
  }

  static getLoginUrl(){
      let tmp = AGRest.getServerRESTUrl()
      return tmp + "/Login";
  }

  static async login(server_rest, user_name, password, time_zone, timeout=30000){
      let body = {
          user_name : user_name,
          password : password,
          time_zone : time_zone
      }
      let resource = server_rest + "/Login"
      return AGRest.fetchWithTimeoutJSON(resource, 'POST', '',timeout, body )
  }

  static async logout(){
    let resource = AGRest.getServerRESTUrl() + "/Logout";
    let access_token = localStorage.getItem("access_token")
    return AGRest.fetchWithTimeoutJSON(resource, 'DELETE', access_token)
  }

  static getServerRESTUrl(){
      return localStorage.getItem("server_rest")
  }

  static getServerRESTUrlFromWebBrowser(){
    let splits = window.location.href.split("/");
    let tmp = splits[2].split(":")
    return splits[0] + "//" + tmp[0]+ "/Api"
  }

  static async getIp() {
    const response = await fetch("https://geolocation-db.com/json/");
    const data = await response.json();
    return data;
  }

  static async getSites(timeout=80000){
    let resource = AGRest.getServerRESTUrl() + "/Data/Sites/"
    let access_token = localStorage.getItem("access_token")
    return AGRest.fetchWithTimeoutJSON(resource, 'GET', access_token,timeout)
  }

  static async getSlas(timeout=80000){
    let resource = AGRest.getServerRESTUrl() + "/Data/Slas/";
    let access_token = localStorage.getItem("access_token")
    return AGRest.fetchWithTimeoutJSON(resource, 'GET', access_token,timeout)
  }

  static async getDictionaries(param, timeout=80000){
    let resource = AGRest.getServerRESTUrl() + "/Data/Dictionary/?type=" + param
    let access_token = localStorage.getItem("access_token")
    return AGRest.fetchWithTimeoutJSON(resource, 'GET', access_token, timeout)
  }

  static async getSystemInfo(timeout=80000){
    let resource = AGRest.getServerRESTUrl() + "/System/Info"
    let access_token = localStorage.getItem("access_token")
    return AGRest.fetchWithTimeoutJSON(resource, 'GET', access_token,timeout)
  }

  static async getServices(timeout=80000){
    let resource = AGRest.getServerRESTUrl() + "/Data/Services/"
    let access_token = localStorage.getItem("access_token")
    return AGRest.fetchWithTimeoutJSON(resource, 'GET', access_token,timeout)
  }

  static async insertSla(sla, timeout=80000){
    let resource = AGRest.getServerRESTUrl() + "/Data/Slas/";
    let body = sla

    let access_token = localStorage.getItem("access_token")
    return AGRest.fetchWithTimeoutJSON(resource, 'POST', access_token,timeout, body)
  }

  static async updateSla(sla, timeout=80000){
    let resource = AGRest.getServerRESTUrl() + "/Data/Slas/" + sla._id;

    let body = {
      _id: sla._id,
      code : sla.code,
      description : sla.description,
      status : sla.status,
      services : sla.services,
      customer_code : sla.customer_code,
      customer : sla.customer,
      impact : sla.impact,
      category : sla.category,
      availability : sla.availability,
      notes : sla.notes
    }

    let access_token = localStorage.getItem("access_token")
    return AGRest.fetchWithTimeoutJSON(resource, 'PUT', access_token,timeout, body)
  }

  static async insertService(service, pattern_date, timeout=80000){
    let resource = AGRest.getServerRESTUrl() + "/Data/Services/";

    let body = {
      code : service.code,
      description : service.description,
      name : service.name,
      customer_code : service.customer_code,
      customer : service.customer,
      activation_date : createDateTimeZone(service.activation_date, true, pattern_date, false),
      state_code : service.state_code,
      state : service.state,
      category_code : service.category_code,
      category : service.category,
      mux : service.mux,
      groups : service.groups,
    }

    let access_token = localStorage.getItem("access_token")
    return AGRest.fetchWithTimeoutJSON(resource, 'POST', access_token,timeout, body)
  }

  static async updateService(service, pattern_date, timeout=80000){
    let resource = AGRest.getServerRESTUrl() + "/Data/Services/" + service._id;

    let body = {
      _id : service._id,
      code : service.code,
      description : service.description,
      name : service.name,
      customer_code : service.customer_code,
      customer : service.customer,
      activation_date : createDateTimeZone(service.activation_date, true, pattern_date, false),
      state_code : service.state_code,
      state : service.state,
      category_code : service.category_code,
      category : service.category,
      mux : service.mux,
      groups : service.groups
    }

    let access_token = localStorage.getItem("access_token")
    return AGRest.fetchWithTimeoutJSON(resource, 'PUT', access_token,timeout, body)
  }

  static async deleteService(service, timeout=80000){
    let resource = AGRest.getServerRESTUrl() + "/Data/Services/" + service._id;
    let access_token = localStorage.getItem("access_token")
    return AGRest.fetchWithTimeoutJSON(resource, 'DELETE', access_token,timeout)
  }

  static async deleteSla(sla, timeout=80000){
    let resource = AGRest.getServerRESTUrl() + "/Data/Slas/" + sla._id;
    let access_token = localStorage.getItem("access_token")
    return AGRest.fetchWithTimeoutJSON(resource, 'DELETE', access_token,timeout)
  }

  static async getFEWidget(timeout=80000){
    let resource = AGRest.getServerRESTUrl() + "/Data/System/FE/Widget/"
    let access_token = localStorage.getItem("access_token")
    return AGRest.fetchWithTimeoutJSON(resource, 'GET', access_token,timeout)
  }

  static async insertFEWidget(type, widget, timeout=80000){
    let resource = AGRest.getServerRESTUrl() + "/Data/System/FE/Widget/";

    let body = {
      code : widget.name,
      type : type,
      widget : widget,
      groups_read : []
    }

    let access_token = localStorage.getItem("access_token")
    return AGRest.fetchWithTimeoutJSON(resource, 'POST', access_token,timeout, body)
  }

  static async updateFEWidget(id, type, widget, user_group, timeout=80000){
    let resource = AGRest.getServerRESTUrl() + "/Data/System/FE/Widget/" + id;

    let body = {
      _id : id,
      type : type,
      widget : widget,
      groups_read : user_group
    }

    let access_token = localStorage.getItem("access_token")
    return AGRest.fetchWithTimeoutJSON(resource, 'PUT', access_token,timeout, body)
  }

  static async deleteFEWidget(id, timeout=80000){
    let resource = AGRest.getServerRESTUrl() + "/Data/System/FE/Widget/" + id
    let access_token = localStorage.getItem("access_token")
    return AGRest.fetchWithTimeoutJSON(resource, 'DELETE', access_token,timeout)
  }

  static async refreshToken(timeout=80000){
    let resource = AGRest.getServerRESTUrl() + "/TokenRefresh";
    let access_token = localStorage.getItem("refresh_token")
    return AGRest.fetchWithTimeoutJSON(resource, 'POST', access_token, timeout, {} )
  }

  static async getAdditionalFields(type, code, timeout=80000){
    let resource = AGRest.getServerRESTUrl() + "/Data/System/FE/Settings/"
    let parameters = "";
    let first_param = "";
    let tmp_type = "", tmp_code = "";

    if(type != null && type != "")
    {
      first_param = "?";
      tmp_type = "type=" + type + "&";
    }

    if(code != null && code != "")
    {
      first_param = "?";
      tmp_code = "code=" + code + "&";
    }

    parameters = first_param + tmp_type + tmp_code;

    resource = resource+parameters 
    let access_token = localStorage.getItem("access_token")
    return AGRest.fetchWithTimeoutJSON(resource, 'GET', access_token,timeout)
  }

  static async updateSite(site, timeout=80000){
    let resource = AGRest.getServerRESTUrl() + "/Data/Sites/" + site._id;

    let body = {
      _id: site._id,
      description: site.description,
      address: site.address,
      region: site.region,
      classification: site.classification,
      altitude: site.altitude,
      province: site.province,
      user: site.user,
      latitude: site.latitude,
      longitude: site.longitude,
      size_icon: site.size_icon,
      state: site.state,
      operation_center: site.operation_center,
      tags: site.tags,
      note: site.note,
      site_code: site.site_code,
      restore_hours: site.restore_hours,
      maintenance_hours: site.maintenance_hours,
      travel_hours: site.travel_hours,
      limitation: site.limitation,
      building_config: site.building_config,
      stg_presence: site.stg_presence,
      permissions: site.permissions
    }

    let access_token = localStorage.getItem("access_token")
    return AGRest.fetchWithTimeoutJSON(resource, 'PUT', access_token,timeout, body)
  }

  static async getAllTaskSchedulation(timeout=80000){
    let resource = AGRest.getServerRESTUrl() + "/Schedule/NetPOD/Communicator/Task/"
    let access_token = localStorage.getItem("access_token")
    return AGRest.fetchWithTimeoutJSON(resource, 'GET', access_token,timeout)
  }

  static async runTask(task, from_date, to_date, timeout=80000){
    let resource = AGRest.getServerRESTUrl() + "/Schedule/NetPOD/Communicator/SubmitTask/";
    let body = {};

    if(task == "3")
    {
      body = {
        task: task,
        params:{
            from_date: from_date,
            to_date: to_date
        }
      }
    }
    else
    {
      body = {
        task: task,
      }
    }

    let access_token = localStorage.getItem("access_token")
    return AGRest.fetchWithTimeoutJSON(resource, 'POST', access_token,timeout, body)
  }

  static async enabledTask(task, obj_task, timeout=80000){
    let resource = AGRest.getServerRESTUrl() + "/Schedule/NetPOD/Communicator/Task/";
    let body = {};

    if(task == "3")
    {
      body = {
        task: task,
        schedule_type: obj_task.schedule_type,
        hour: obj_task.hour,
        minute: obj_task.minute
      }
    }
    else
    {
      body = {
        task: task,
        interval: obj_task.interval,
        schedule_type: obj_task.schedule_type
      }
    }

    let access_token = localStorage.getItem("access_token")
    return AGRest.fetchWithTimeoutJSON(resource, 'POST', access_token,timeout, body)
  }

  static async disableTask(number_task, timeout=80000){
    let resource = AGRest.getServerRESTUrl() + "/Schedule/NetPOD/Communicator/Task/" + number_task
    let access_token = localStorage.getItem("access_token")
    return AGRest.fetchWithTimeoutJSON(resource, 'DELETE', access_token,timeout)
  }

  static async getFEConfigurationMeasures(timeout=80000){
    let resource = AGRest.getServerRESTUrl() + "/Data/System/FE/MeasuresConfiguration/"
    let access_token = localStorage.getItem("access_token")
    return AGRest.fetchWithTimeoutJSON(resource, 'GET', access_token,timeout)
  }

  static async insertFEConfigurationMeasures(code, measure, device_id, configuration, timeout=80000){
    let resource = AGRest.getServerRESTUrl() + "/Data/System/FE/MeasuresConfiguration/";

    let body = {
      code : code,
      measure : measure,
      device : device_id,
      configuration : configuration,
      groups_read : []
    }

    let access_token = localStorage.getItem("access_token")
    return AGRest.fetchWithTimeoutJSON(resource, 'POST', access_token,timeout, body)
  }

  static async updateFEConfigurationMeasures(id, measure, device_id, configuration, user_group, timeout=80000){
    let resource = AGRest.getServerRESTUrl() + "/Data/System/FE/MeasuresConfiguration/" + id;

    let body = {
      _id : id,
      measure : measure,
      device : device_id,
      configuration : configuration,
      groups_read : user_group
    }

    let access_token = localStorage.getItem("access_token")
    return AGRest.fetchWithTimeoutJSON(resource, 'PUT', access_token,timeout, body)
  }

  static async deleteFEConfigurationMeasures(id, timeout=80000){
    let resource = AGRest.getServerRESTUrl() + "/Data/System/FE/MeasuresConfiguration/" + id
    let access_token = localStorage.getItem("access_token")
    return AGRest.fetchWithTimeoutJSON(resource, 'DELETE', access_token,timeout)
  }

  static async getFEUserDashboard(user_id, timeout=80000){
    let resource = AGRest.getServerRESTUrl() + "/Data/System/FE/UserDashboard/" + user_id
    let access_token = localStorage.getItem("access_token")
    return AGRest.fetchWithTimeoutJSON(resource, 'GET', access_token,timeout)
  }

  static async insertFEUserDashboard(title, user, dashboard, timeout=80000){
    let resource = AGRest.getServerRESTUrl() + "/Data/System/FE/UserDashboard/";

    let body = {
      title : title,
      user : user,
      dashboard : dashboard
    }

    let access_token = localStorage.getItem("access_token")
    return AGRest.fetchWithTimeoutJSON(resource, 'POST', access_token,timeout, body)
  }

  static async updateFEUserDashboard(id, user, dashboard, timeout=80000){
    let resource = AGRest.getServerRESTUrl() + "/Data/System/FE/UserDashboard/" + id;

    let body = {
      _id : id,
      user : user,
      dashboard : dashboard
    }

    let access_token = localStorage.getItem("access_token")
    return AGRest.fetchWithTimeoutJSON(resource, 'PUT', access_token,timeout, body)
  }

  static async deleteFEUserDashboard(id, timeout=80000){
    let resource = AGRest.getServerRESTUrl() + "/Data/System/FE/UserDashboard/" + id
    let access_token = localStorage.getItem("access_token")
    return AGRest.fetchWithTimeoutJSON(resource, 'DELETE', access_token,timeout)
  }

  static async getSystemSettings(timeout=80000){
    let resource = AGRest.getServerRESTUrl() + "/Data/System/Settings/"
    let access_token = localStorage.getItem("access_token")
    return AGRest.fetchWithTimeoutJSON(resource, 'GET', access_token,timeout)
  }

  static async updateSystemSettings(id, performance, general, microservice, timeout=80000){
    let resource = AGRest.getServerRESTUrl() + "/Data/System/Settings/" + id;

    let body = {
      _id: id,
      performance: performance,
      general: general,
      microservice: microservice
    }

    let access_token = localStorage.getItem("access_token")
    return AGRest.fetchWithTimeoutJSON(resource, 'PUT', access_token,timeout, body)
  }

  static async getAllNetPODExportList(timeout=80000){
    let resource = AGRest.getServerRESTUrl() + "/Schedule/NetPOD/Exporter/Export/"
    let access_token = localStorage.getItem("access_token")
    return AGRest.fetchWithTimeoutJSON(resource, 'GET', access_token,timeout)
  }

  static async createExportNetPOD(devices_id, from_date, to_date, timeout=80000){
    let resource = AGRest.getServerRESTUrl() + "/Schedule/NetPOD/Exporter/Export/";

    let body = {
      from_date : from_date,
      to_date : to_date,
      device_ids : devices_id
    }

    let access_token = localStorage.getItem("access_token")
    return AGRest.fetchWithTimeoutJSON(resource, 'POST', access_token,timeout, body)
  }

  static async runDataMigration(timeout=80000){
    //TODO da definire
    let resource = "" //AGRest.getServerRESTUrl() + "/Data/Slas/"; 
    let body = {}

    let access_token = localStorage.getItem("access_token")
    return AGRest.fetchWithTimeoutJSON(resource, 'POST', access_token,timeout, body)
  }

  static async getMUX(timeout=80000){
    let resource = AGRest.getServerRESTUrl() + "/Data/Mux/"
    let access_token = localStorage.getItem("access_token")
    return AGRest.fetchWithTimeoutJSON(resource, 'GET', access_token,timeout)
  }

  static async insertMUX(mux, timeout=80000){
    let resource = AGRest.getServerRESTUrl() + "/Data/Mux/";

    let body = {
      code : mux.code,
      description : mux.description,
      services : mux.services
    }

    let access_token = localStorage.getItem("access_token")
    return AGRest.fetchWithTimeoutJSON(resource, 'POST', access_token,timeout, body)
  }

  static async updateMUX(mux, timeout=80000){
    let resource = AGRest.getServerRESTUrl() + "/Data/Mux/" + mux._id;

    let body = {
      _id : mux._id,
      description : mux.description,
      services : mux.services
    }

    let access_token = localStorage.getItem("access_token")
    return AGRest.fetchWithTimeoutJSON(resource, 'PUT', access_token,timeout, body)
  }

  static async deleteMUX(mux_id, timeout=80000){
    let resource = AGRest.getServerRESTUrl() + "/Data/Mux/" + mux_id;
    let access_token = localStorage.getItem("access_token")
    return AGRest.fetchWithTimeoutJSON(resource, 'DELETE', access_token,timeout)
  }
}