import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
// don't want to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init

const Languages = ['en', 'it']

let language = 'en';

if(window.localStorage.getItem('i18nextLng') != null)
{
  language = window.localStorage.getItem('i18nextLng');

  if(language == 'auto')
  {
    let user_language = window.navigator.userLanguage || window.navigator.language;
    let tmp_split = user_language.split("-") //per ora gestisco en-GB e en-US uguali prendendo solo en
    language = tmp_split[0]
  }

}



i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  
  //LanguageDetector riconosce la lingua del browser
  //.use(LanguageDetector)
  
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: language,
    debug: true,
    whitelist: Languages,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });


export default i18n;