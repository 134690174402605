import React from 'react';
import { HashRouter } from 'react-router-dom';
import Layout from 'layouts/Layout';

class App extends React.PureComponent {
  render() {
    return (
        <div>
          <HashRouter>
            <Layout />
          </HashRouter>
        </div>
    );
  }
}

export default App;