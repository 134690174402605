import moment from 'moment'
import "moment-timezone";
import * as XLSX from 'xlsx'

import size_0_normal from 'assets/img/markers/0/00-0.png';
import size_0_critical from 'assets/img/markers/0/00-4.png';
import size_0_maintenance from 'assets/img/markers/0/00-3.png';
import size_0_unknown from 'assets/img/markers/0/00-5.png';
import size_1_normal from 'assets/img/markers/1/00-0.png';
import size_1_critical from 'assets/img/markers/1/00-4.png';
import size_1_maintenance from 'assets/img/markers/1/00-3.png';
import size_1_unknown from 'assets/img/markers/1/00-5.png';
import size_2_normal from 'assets/img/markers/2/00-0.png';
import size_2_critical from 'assets/img/markers/2/00-4.png';
import size_2_maintenance from 'assets/img/markers/2/00-3.png';
import size_2_unknown from 'assets/img/markers/2/00-5.png';
import size_3_normal from 'assets/img/markers/3/00-0.png';
import size_3_critical from 'assets/img/markers/3/00-4.png';
import size_3_maintenance from 'assets/img/markers/3/00-3.png';
import size_3_unknown from 'assets/img/markers/3/00-5.png';
import size_4_normal from 'assets/img/markers/4/00-0.png';
import size_4_critical from 'assets/img/markers/4/00-4.png';
import size_4_maintenance from 'assets/img/markers/4/00-3.png';
import size_4_unknown from 'assets/img/markers/4/00-5.png';
import size_5_normal from 'assets/img/markers/5/00-0.png';
import size_5_critical from 'assets/img/markers/5/00-4.png';
import size_5_maintenance from 'assets/img/markers/5/00-3.png';
import size_5_unknown from 'assets/img/markers/5/00-5.png';
import size_0_normal_unknown from 'assets/img/markers/0/01-0.png';
import size_1_normal_unknown from 'assets/img/markers/1/01-0.png';
import size_2_normal_unknown from 'assets/img/markers/2/01-0.png';
import size_3_normal_unknown from 'assets/img/markers/3/01-0.png';
import size_4_normal_unknown from 'assets/img/markers/4/01-0.png';
import size_5_normal_unknown from 'assets/img/markers/5/01-0.png';

export function formatErrorResponseMessage(error, rest_error){
    let message = "Generic error. Error: " + rest_error + ". Error: " + error;
    return message
}

export function formatResponseMessage(response, rest_error){
    let message = "Error method: " + rest_error + ". Status: " + response.response.status;
    if(response.data != null){
        if(response.data.message != null){
            message = message + " - " + response.data.message
        }
    }
    return message
}

export async function fetchWithTimeoutRaw(resource,options) {
    const { timeout = 20000 } = options;
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);

    const response = await fetch(resource, {
      ...options,
      signal: controller.signal
    });
    clearTimeout(id);
    return response;
}

export function retrieveColorAlarm(code){
    if(code == "normal"){
        return "#00BB2D";
    }
    if(code == "critical"){
        return "#dc3545";
    }
    if(code == "maintenance"){
        return "#d5873d";
        
    }
    if(code == "unknown"){
        return "#808080";     
    }
}

export function groupMarkerBySite(list_performances){
    let list_sites = [];
    let map = new Map();

    if(list_performances != null && list_performances.length > 0)
    {
        for(let i = 0; i < list_performances.length; i++)
        {
            if(list_performances[i].site != null && list_performances[i].site._id != null)
            {
                if(!list_sites.includes(list_performances[i].site._id))
                {
                    list_sites.push(list_performances[i].site._id);
                    let new_array = []
                    new_array.push(list_performances[i])
                    map.set(list_performances[i].site._id, new_array);
                }
                else
                {
                    let old_array = map.get(list_performances[i].site._id);
                    old_array.push(list_performances[i]);
                    map.set(list_performances[i].site._id, old_array);
                }
                
            }
        }
    }

    return map;
}

export function calculateIconUrl(size, status){
    if(size == 0){
        if(status == "critical"){
            return size_0_critical;
        }  
        else if(status == "maintenance"){
            return size_0_maintenance;
        }
        else if(status == "normal_unknown"){
            return size_0_normal_unknown;
        }
        else if(status == "unknown"){
            return size_0_unknown;
        }
        else if(status == "normal"){
            return size_0_normal;
        }
    }
    else if(size == 1){
        if(status == "critical"){
            return size_1_critical;
        }  
        else if(status == "maintenance"){
            return size_1_maintenance;
        }
        else if(status == "normal_unknown"){
            return size_1_normal_unknown;
        }
        else if(status == "unknown"){
            return size_1_unknown;
        }
        else if(status == "normal"){
            return size_1_normal;
        }
    }
    else if(size == 2){
        if(status == "critical"){
            return size_2_critical;
        }  
        else if(status == "maintenance"){
            return size_2_maintenance;
        }
        else if(status == "normal_unknown"){
            return size_2_normal_unknown;
        }
        else if(status == "unknown"){
            return size_2_unknown;
        }
        else if(status == "normal"){
            return size_2_normal;
        }
    }
    else if(size == 3){
        if(status == "critical"){
            return size_3_critical;
        }  
        else if(status == "maintenance"){
            return size_3_maintenance;
        }
        else if(status == "normal_unknown"){
            return size_3_normal_unknown;
        }
        else if(status == "unknown"){
            return size_3_unknown;
        }
        else if(status == "normal"){
            return size_3_normal;
        }
    } 
    else if(size == 4){
        if(status == "critical"){
            return size_4_critical;
        }  
        else if(status == "maintenance"){
            return size_4_maintenance;
        }
        else if(status == "normal_unknown"){
            return size_4_normal_unknown;
        }
        else if(status == "unknown"){
            return size_4_unknown;
        }
        else if(status == "normal"){
            return size_4_normal;
        }
    } 
    else if(size == 5){
        if(status == "critical"){
            return size_5_critical;
        }  
        else if(status == "maintenance"){
            return size_5_maintenance;
        }
        else if(status == "normal_unknown"){
            return size_5_normal_unknown;
        }
        else if(status == "unknown"){
            return size_5_unknown;
        }
        else if(status == "normal"){
            return size_5_normal;
        }
    }  
}

export function calculateIconSizeAnchor(size){
    if(size == 0){
       return[[26, 18], [13.5, 18]] 
    }
    else if(size == 1){
        return[[28, 21], [14.5, 21]] 
    }
    else if(size == 2){
        return[[30, 24], [15.5, 24]] 
    }
    else if(size == 3){
        return[[34, 26], [17, 26]] 
    } 
    else if(size == 4){
        return[[36, 29], [18, 29]] 
    } 
    else if(size == 5){
        return[[38, 31], [19, 31]] 
    } 
    else
    {
        return[[], []]
    } 
}

export function distinctPerformanceRegionProvince(results){
    let map = new Map();

    if(results != null && results.length > 0)
    {
        for(let i = 0; i < results.length; i++)
        {
            if(results[i].region != null && results[i].region != "")
            {
                if(results[i].provinces != null && results[i].provinces.length > 0)
                {
                    let code_map = results[i].provinces.map((p) =>{return p.province})
                    code_map = code_map.sort(function (a, b) {
                        return a.toLowerCase().localeCompare(b.toLowerCase());
                    });
                    map.set(results[i].region, code_map);
                }
            }
        }
    }

    return map;
}

export function distinctPerformanceRegionOC(results){
    let map = new Map();

    if(results != null && results.length > 0)
    {
        for(let i = 0; i < results.length; i++)
        {
            if(results[i].region != null && results[i].region != "")
            {
                if(results[i].operation_centers != null && results[i].operation_centers.length > 0)
                {
                    let code_map = results[i].operation_centers.map((p) =>{return p.operation_center})
                    code_map = code_map.sort(function (a, b) {
                        return a.toLowerCase().localeCompare(b.toLowerCase());
                    });
                    map.set(results[i].region, code_map);
                }
            }
        }
    }

    return map;
}


export function distinctPerformanceOC(results)
{
    let map = new Map();

    if(results != null && results.length > 0)
    {
        for(let i = 0; i < results.length; i++)
        {
            if(results[i].operation_center != null && results[i].operation_center != "")
            {
                if(results[i].sites != null && results[i].sites.length > 0)
                {
                    let code_map = results[i].sites.map((p) =>{return p.site})
                    code_map = code_map.sort(function (a, b) {
                        return a.toLowerCase().localeCompare(b.toLowerCase());
                    });
                    map.set(results[i].operation_center, code_map);
                }
            }
        }
    }

    return map;
}

export function distinctPerformanceClassification(results){
    let map = new Map();

    if(results != null && results.length > 0)
    {
        let priorities = ['Platinum Plus', 'Platinum', 'Gold', 'Silver', 'Bronze', 'Copper'];
        let order_class = results.sort((a, b) => priorities.indexOf(a.classification) - priorities.indexOf(b.classification));

        for(let i = 0; i < order_class.length; i++)
        {
            if(order_class[i].classification != null && order_class[i].classification != "")
            {
                if(order_class[i].sites != null && order_class[i].sites.length > 0)
                {
                    let code_map = order_class[i].sites.map((p) =>{return p.site})
                    map.set(order_class[i].classification, code_map);
                }
            }
        }
    }

    return map;
}

export function distinctProvinceValue(list_performance, type_value_chart){
    let map = new Map();

    if(list_performance != null && list_performance.provinces != null && list_performance.provinces.length > 0)
    {
        for(let i = 0; i < list_performance.provinces.length; i++)
        {
            let provincia = list_performance.provinces[i];
            let calculation = provincia != null ? provincia.calculation : null
            let code_provincia= provincia != null ? provincia.province : ""

            let performance = {
                "normal": type_value_chart == "percentage" ? calculation["nrm"] : calculation["rnrm"],
                "critical": type_value_chart == "percentage" ? calculation["crt"] : calculation["rcrt"],
                "maintenance": type_value_chart == "percentage" ? calculation["mnt"] : calculation["rmnt"],
                "unknown": type_value_chart == "percentage" ? calculation["unk"] : calculation["runk"],
                "count_normal": calculation["c_nrm"],
                "count_critical": calculation["c_crt"],
                "count_maintenance": calculation["c_mnt"],
                "count_unknown": calculation["c_unk"]
            }

            map.set(code_provincia, performance)
        }
    }
    
    return map;
}

export function distinctOperationCenterValue(list_performance, type_value_chart){
    let map = new Map();

    if(list_performance != null && list_performance.operation_centers != null && list_performance.operation_centers.length > 0)
    {
        for(let i = 0; i < list_performance.operation_centers.length; i++)
        {
            let operation_center = list_performance.operation_centers[i];
            let calculation = operation_center != null ? operation_center.calculation : null
            let code_operation_center = operation_center != null ? operation_center.operation_center : ""

            let performance = {
                "normal": type_value_chart == "percentage" ? calculation["nrm"] : calculation["rnrm"],
                "critical": type_value_chart == "percentage" ? calculation["crt"] : calculation["rcrt"],
                "maintenance": type_value_chart == "percentage" ? calculation["mnt"] : calculation["rmnt"],
                "unknown": type_value_chart == "percentage" ? calculation["unk"] : calculation["runk"],
                "count_normal": calculation["c_nrm"],
                "count_critical": calculation["c_crt"],
                "count_maintenance": calculation["c_mnt"],
                "count_unknown": calculation["c_unk"]
            }

            map.set(code_operation_center, performance)
        }
    }
    
    return map;
}

export function distinctPerformancesValue(list_performance, type_value_chart){
    let map = new Map();

    if(list_performance != null && list_performance["performances"] != null && list_performance["performances"].length > 0)
    {
        for(let i = 0; i < list_performance["performances"].length; i++)
        {
            let performances = list_performance["performances"][i];
            let calculation = performances != null ? performances.calculation : null
            let code_performances= performances != null ? performances.description : ""

            let performance = {
                "normal": type_value_chart == "percentage" ? calculation["nrm"] : calculation["rnrm"],
                "critical": type_value_chart == "percentage" ? calculation["crt"] : calculation["rcrt"],
                "maintenance": type_value_chart == "percentage" ? calculation["mnt"] : calculation["rmnt"],
                "unknown": type_value_chart == "percentage" ? calculation["unk"] : calculation["runk"],
                "count_normal": calculation["c_nrm"],
                "count_critical": calculation["c_crt"],
                "count_maintenance": calculation["c_mnt"],
                "count_unknown": calculation["c_unk"]
            }

            map.set(code_performances, performance)
        }
    }
    
    return map;
}

export function distinctSiteValue(list_performance_by_province, type_value_chart){
    let map = new Map();

    if(list_performance_by_province != null && list_performance_by_province.sites != null && list_performance_by_province.sites.length > 0)
    {
        for(let i = 0; i < list_performance_by_province.sites.length; i++)
        {
            let site = list_performance_by_province.sites[i];
            let calculation = site != null ? site.calculation : null
            let description_site= site != null ? site.site : ""

            let performance = {
                "normal": type_value_chart == "percentage" ? calculation["nrm"] : calculation["rnrm"],
                "critical": type_value_chart == "percentage" ? calculation["crt"] : calculation["rcrt"],
                "maintenance": type_value_chart == "percentage" ? calculation["mnt"] : calculation["rmnt"],
                "unknown": type_value_chart == "percentage" ? calculation["unk"] : calculation["runk"],
                "count_normal": calculation["c_nrm"],
                "count_critical": calculation["c_crt"],
                "count_maintenance": calculation["c_mnt"],
                "count_unknown": calculation["c_unk"]
            }

            map.set(description_site, performance)
        }
    }
    
    return map;
}

export function createDictionariesDropDownUsers(list_user)
{
    let data = [];

    let order_list_user = list_user.sort(function (a, b) {
        return a.user_name.toLowerCase().localeCompare(b.user_name.toLowerCase());
      })

    if(order_list_user != null && order_list_user.length > 0)
    {
        for(let f = 0; f < order_list_user.length; f++)
        {
            let tmp = {
                key: order_list_user[f]._id, 
                text: order_list_user[f].user_name, 
                value: order_list_user[f]._id
            }

            data.push(tmp);
        }
    }

    return data;
}

export function createDictionariesDropDownFilter(user_configuration)
{
    let data = [];   

    if(user_configuration != null && Object.keys(user_configuration.front_end_web).length > 0 && user_configuration.front_end_web.saved_filters != null && user_configuration.front_end_web.saved_filters.length > 0)
    {
        let list_filters = user_configuration.front_end_web.saved_filters;
        for(let f = 0; f < list_filters.length; f++)
        {
            let tmp = {
                key: list_filters[f].name, //uso key il nome poichè è univoco
                text: list_filters[f].name, 
                value: list_filters[f].name
            }

            data.push(tmp);
        }
    }

    return data;
}

export function createDictionariesDropDownReport(list_report)
{
    let data = [];

    let order_list = list_report.sort(function (a, b) {
        return a.description.toLowerCase().localeCompare(b.description.toLowerCase());
      })

    if(order_list != null && order_list.length > 0)
    {
        for(let r = 0; r < order_list.length; r++)
        {

            if(order_list[r].enabled) //solo se sono abilitati a essere associati
            {
                let tmp = {
                    key: order_list[r]._id, 
                    text: order_list[r].description, 
                    value: order_list[r]._id
                }

                data.push(tmp);
            }
        }
    }

    return data;
}

export function createDictionariesDropDownFEWidget(list_fe_widget)
{
    let data = [];

    let all_filter = list_fe_widget.filter((f) => {return f.type == "filter"});

    if(all_filter != null && all_filter.length > 0)
    {

        let order_list = all_filter.sort(function (a, b) {
            return a.code.toLowerCase().localeCompare(b.code.toLowerCase());
        })

        for(let r = 0; r < order_list.length; r++)
        {
            let tmp = {
                key: order_list[r].code, 
                text: order_list[r].code, 
                value: order_list[r].code
            }

            data.push(tmp);
        }
    }

    return data;
}

export function createDictionariesDropDownContent(contents)
{
    let data = [];

    let order_list = contents.sort(function (a, b) {
        return a.text.toLowerCase().localeCompare(b.text.toLowerCase());
      })

    if(order_list != null && order_list.length > 0)
    {
        for(let r = 0; r < order_list.length; r++)
        {
            let tmp = {
                key: order_list[r].id, 
                text: order_list[r].text, 
                value: order_list[r].id
            }

            data.push(tmp); 
        }
    }

    return data;
}

export function createDictionariesDropDownServices(list_services)
{
    let data = [];

    let order_list = list_services.sort(function (a, b) {
        return a.description.toLowerCase().localeCompare(b.description.toLowerCase());
      })

    if(order_list != null && order_list.length > 0)
    {
        for(let r = 0; r < order_list.length; r++)
        {
            let tmp = {
                key: order_list[r]._id, 
                text: order_list[r].name, 
                value: order_list[r].code
            }

            data.push(tmp);  
        }
    }

    return data;
}

export function calculatePositionSite(list_markers)
{
    let list_position = [];

    if(list_markers != null && list_markers.length > 0)
    {
        for(let i = 0; i < list_markers.length; i++)
        {
            
            if(list_markers[i].site != null && list_markers[i].performances != null && list_markers[i].performances.length > 0)
            {
                //prendo la prima performances perchè ha cordinate uguale per sito
                let first_performances = list_markers[i].performances[0];
                if(first_performances != null && first_performances.site != null && first_performances.site.latitude != null && first_performances.site.longitude != null )
                {
                    let position = [first_performances.site.latitude, first_performances.site.longitude];
                    list_position.push(position)
                }
            }
        }
    }

    return list_position;
}

export function maxBoundsSite(list_preformances){
    let position_1 = [];
    let position_2 = [];

    if(list_preformances != null && list_preformances.length > 0)
    {
        let max_latitude = Math.max.apply(Math, list_preformances.map(function(o) { return o.site.latitude; }))
        let max_longitude = Math.max.apply(Math, list_preformances.map(function(o) { return o.site.longitude; }))

        let min_latitude = Math.min.apply(Math, list_preformances.map(function(o) { return o.site.latitude; }))
        let min_longitude = Math.min.apply(Math, list_preformances.map(function(o) { return o.site.longitude; }))

        position_1 = [max_latitude, max_longitude];
        position_2 = [min_latitude, min_longitude];
    }

    return [position_1, position_2];
}

export function addDelimitationRestParameter(value, is_single_param) {
    let result;

    if(is_single_param)
    {
        if(value.includes(','))
        {
            value = '"'+ value + '"'
        }

        result = value;
    }
    else
    {
        let list_result = [];
        if(value != null && value.length > 0)
        {
            for(let i = 0; i < value.length; i++)
            {
                let tmp_value = value[i].toString();
                if(tmp_value.includes(','))
                {
                    tmp_value = '"'+ value[i] + '"'
                }
                
                list_result.push(tmp_value)
            }
        }

        result = list_result;
    }

    return result;
}

export function calculateLevel(t, level)
{
    let tmp_level = "";

    if(level == 0)
    {
        tmp_level = t('user_level.custom');
    }
    else if(level == 1)
    {
        tmp_level = t('user_level.guest');
    }
    else if(level == 2)
    {
        tmp_level = t('user_level.limited');
    }
    else if(level == 3)
    {
        tmp_level = t('user_level.normal');
    }
    else if(level == 4)
    {
        tmp_level = t('user_level.advanced');
    }
    else if(level == 5)
    {
        tmp_level = t('user_level.admin');
    }
    else if(level == 6)
    {
        tmp_level = "Super Admin";
    }
    
    return tmp_level;
}

export function calculateSource(source){
    if(source == 1)
    {
      return "NetPOD"
    }
    else if(source == 2)
    {
      return "Internal"
    }
    else if(source == 3)
    {
      return "Ldap"
    }
}

export function handleUniformReportToPut(list_report)
{
  let new_list_report = [];

  if(list_report != null && list_report.length > 0)
  {
    for(let i = 0; i < list_report.length; i++)
    {
      let report = {
        report: list_report[i].report,
        scheduled: false,
        schedule_parameters: {}
      } 

      new_list_report.push(report);
    }
  }

  return new_list_report;
}


export function createListKeyMarker(list_markers)
{
    let list_key = [];

    if(list_markers != null && list_markers.length > 0)
    {
        for(let i = 0; i < list_markers.length; i++)
        {
            if(list_markers[i].performances != null && list_markers[i].performances.length > 0 && list_markers[i].performances[0].site != null)
            {
                for(let y = 0; y < list_markers[i].performances.length; y++)
                {
                    let performances = list_markers[i].performances[y];
                    let site = performances.site;

                    if(site != null)
                    {
                        let value = performances.site._id + "_" + performances._id +  "_" + performances.status.value;
                        list_key.push(value);
                    }
                }
            }
        }
    }

    return list_key.sort();
}

export function createListKeySimplyResponse(list_performances)
{
    let list_key = [];

    if(list_performances != null && list_performances.length > 0)
    {
        for(let i = 0; i < list_performances.length; i++)
        {
            if(list_performances[i].site != null && list_performances[i].status != null)
            {
                let value = list_performances[i].site._id + "_" + list_performances[i]._id +  "_" + list_performances[i].status.value;
                list_key.push(value); 
            }
        }
    }

    return list_key.sort();
}

export function createPatternExclusions(list_exclusions, pattern_date)
{
    let new_exclusion = [];

    if(list_exclusions != null && list_exclusions.length > 0)
    {
        let order_by_from_date = list_exclusions.sort(function(a,b){
            return new Date(a.from_date) - new Date(b.from_date);
        });

        for(let i = 0; i < order_by_from_date.length; i++)
        {
            let exclusion = order_by_from_date[i];

            let tmp = {
                "_id" : exclusion._id,
                "description" : exclusion.description,
                "impacted_performances": exclusion.performances != null ? exclusion.performances.length : 0,
                "from_date" : createDateTimeZone(exclusion.from_date, false, pattern_date, false),
                "to_date" : createDateTimeZone(exclusion.to_date, false, pattern_date, false),
                "duration" : exclusion.duration_str,
                "perc_exclusion" : exclusion.perc_weight,
                "user" : exclusion.properties.creation_user,
                "creation_date": createDateTimeZone(exclusion.properties.creation_date, false, pattern_date, false),
                "performances" : exclusion.performances
            }

            new_exclusion.push(tmp)
        }
    }


    return new_exclusion;
}

export function createPatternUsers(list_users, pattern_date, t)
{
    let new_users = [];

    if(list_users != null && list_users.length > 0)
    {
        for(let i = 0; i < list_users.length; i++)
        {
            let user = list_users[i];

            let format_last_login = user.last_login_datetime != null && user.last_login_datetime != "" ?
                                        createDateTimeZone(user.last_login_datetime, false, pattern_date, false) : "";
            let format_expire_datetime = user.expire && user.expire_datetime != null && user.expire_datetime != "" ? 
                                        createDateTimeZone(user.expire_datetime, false, pattern_date, false) : "";

            let tmp = {
                "_id" : user._id,
                "user_name" : user.user_name,
                "profile" : user.profile,
                "level" : user.level,
                "email": user.email,
                "mobile_number": user.mobile_number,
                "user_org_type" : user.user_org_type,
                "user_org_type_value" : user.user_org_type,
                "num_login" : user.num_login,
                "last_login_datetime" : format_last_login != "" ? moment(format_last_login, pattern_date.toUpperCase() + " HH:mm:ss").valueOf() : "",
                "expire_datetime": format_expire_datetime != "" ? moment(format_expire_datetime, pattern_date.toUpperCase() + " HH:mm:ss").valueOf() : "",
                "user" : user
            }

            new_users.push(tmp)
        }
    }

    return new_users;
}

export function createPatternPerformances(list_performances)
{
    let new_performances = [];

    if(list_performances != null && list_performances.length > 0)
    {
        for(let i = 0; i < list_performances.length; i++)
        {
            let performance = list_performances[i];

            let tmp = {
                "_id" : performance._id,
                "description" : performance.description,
                "code" : performance.site != null ? performance.site.site_code : "",
                "site_description" : performance.site != null ? performance.site.description: "",
                "region" : performance.site != null ? performance.site.region: "",
                "province" : performance.site != null ? performance.site.province: "",
                "classification": performance.site != null ? performance.site.classification: "",
                "operation_center" : performance.site != null ? performance.site.operation_center: "",
                "building_config" : performance.site != null ? performance.site.building_config: "",
                "tags" : performance.tags.join(","),
                "output" : performance.output,
                "tx_channel" : performance.tx_channel,
                "tx_feed1" : performance.tx_feed1,
                "tx_feed2" : performance.tx_feed2,
                "tx_sfn" : performance.tx_sfn,
                "slas" : performance.slas,
                "services" : performance.services,
                "performance" : performance
            }
    
            new_performances.push(tmp)
        }
    }

    return new_performances;
}

export function createPatterInternalReport(list_internal_report)
{
    let new_data = [];

    if(list_internal_report != null && list_internal_report.length > 0)
    {
        for(let i = 0; i < list_internal_report.length; i++)
        {
            let report = list_internal_report[i];
            //filtro i parametri uri e _time_zone_
            let filtered_parameter = [];

            if(report.parameters != null && report.parameters.length > 0)
            {
                filtered_parameter = report.parameters.filter((p) => {return p.parameter_name != "url" && p.parameter_name != "_time_zone_"});
            }

            let tmp = {
                "uri" : report.uri,
                "description" : report.description,
                "category" : report.category,
                "parameters" : filtered_parameter.length,
                "enabled" : report.enabled,
                "report" : report
            }
    
            new_data.push(tmp)
        }
    }

    return new_data;
}


export function createPatternSites(list_sites)
{
    let new_sites = [];

    if(list_sites != null && list_sites.length > 0)
    {
        for(let i = 0; i < list_sites.length; i++)
        {
            let site = list_sites[i];

            let tmp = {
                "_id" : site._id,
                "code" : site.site_code,
                "description" : site.description,
                "region" : site.region,
                "province" : site.province,
                "classification": site.classification,
                "operation_center" : site.operation_center,
                "address": site.address,
                "altitude": site.altitude,
                "latitude": site.latitude,
                "longitude": site.longitude,
                "building_config": site.building_config,
                "maintenance_hours": site.maintenance_hours,
                "restore_hours": site.restore_hours,
                "travel_hours": site.travel_hours,
                "stg_presence": site.stg_presence,
                "note": site.note,
                "site" : site
            }

            new_sites.push(tmp)
        }

        new_sites = new_sites.sort(function (a, b) {
            return a.description.toLowerCase().localeCompare(b.description.toLowerCase());
        });
    }

    return new_sites;
}

export function createPatternUserGroupsArchive(list_users, all_user_groups, list_services, list_devices)
{
    let new_user_groups = [];

    if(all_user_groups != null && all_user_groups.length > 0)
    {
        for(let i = 0; i < all_user_groups.length; i++)
        {
            let user_group = all_user_groups[i];

            let users_read_or_write = list_users.filter((r) => {return (r.groups_read.includes(user_group.code) || r.groups_write.includes(user_group.code))});
            let services_groups = list_services.filter((s) => {return s.groups != null && s.groups.includes(user_group.code)});
            let devices_groups = list_devices.filter((d) => {return (d.permissions.groups_read.includes(user_group.code) || d.permissions.groups_write.includes(user_group.code))});
            let children = all_user_groups.filter((sg) => {return sg.code != user_group.code && sg.code.startsWith(user_group.code)});

            let tmp = {
                "_id" : user_group._id,
                "code" : user_group.code,
                "description" : user_group.description,
                "source" : user_group.source,
                "number_children" : children != null ? children.length : 0,
                "number_user" : users_read_or_write != null ? users_read_or_write.length : 0,
                "number_services": services_groups != null ? services_groups.length : 0,
                "number_devices": devices_groups != null ? devices_groups.length : 0,
                "number_reports": user_group.reports != null ? user_group.reports.length : 0,
            }

            new_user_groups.push(tmp)
        }
    }

    return new_user_groups;
}

export function createPatternFilter(list_filters)
{
    let new_filters = [];

    if(list_filters != null && list_filters.length > 0)
    {
        for(let i = 0; i < list_filters.length; i++)
        {
            let filter = list_filters[i];

            let tmp = {
                "_id" : filter._id,
                "name" : filter.name,
                "type" : filter.type,
                "is_default" : filter.is_default,
                "filter" : filter
            }

            new_filters.push(tmp)
        }
    }

    return new_filters;
}

export function createPatternAdminFilter(list_fe_widget)
{
    let new_filters = [];

    if(list_fe_widget != null && list_fe_widget.length > 0)
    {
        for(let i = 0; i < list_fe_widget.length; i++)
        {
            let widget = list_fe_widget[i].widget;

            let tmp = {
                "name" : widget.name,
                "type" : widget.type,
                "object" : list_fe_widget[i]
            }

            new_filters.push(tmp)
        }

        new_filters = new_filters.sort(function (a, b) {
            return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        });
    }

    return new_filters;
}



export function createDateTimeZone(date, is_insert, pattern_date, is_filter) { //output DB
    const timezone = localStorage.getItem('timezone');
    if(date != null && date != "" && timezone != null && timezone != "")
    {
        if(is_insert)
        {
            if(is_filter)
            {
                let conv_date = new Date(date);
                let new_date = new Date(conv_date.getUTCFullYear(), conv_date.getUTCMonth(), conv_date.getUTCDate(), conv_date.getUTCHours(), conv_date.getUTCMinutes(), conv_date.getUTCSeconds())
                return moment(new_date).tz(timezone).format("YYYY-MM-DDTHH:mm:ss+00:00");
            }
            else
            {
                return moment(date).tz(timezone).format("YYYY-MM-DDTHH:mm:ssZ");
            }
        }
        else
        {
            if(is_filter)
            {
                return moment(date).tz(timezone).format();
            }
            else
            {
                let date_utc = date.concat("+00:00");
                return moment(date_utc).tz(timezone).format(pattern_date.toUpperCase() + " HH:mm:ss");
            }
        }
    }
}

export function checkValidationDate(tmp_end_date, tmp_start_date)
{
  let valid_date = true;

  if(tmp_start_date.getTime() >= tmp_end_date.getTime())
  {
    let start_hour = parseInt(tmp_start_date.getHours());
    let end_hour = parseInt(tmp_end_date.getHours());

    if(end_hour < start_hour)
    {
        valid_date = false;
    }
    else
    {
        let start_minutes = parseInt(tmp_start_date.getMinutes());
        let end_minutes = parseInt(tmp_end_date.getMinutes());

        if(end_minutes < start_minutes)
        {
            valid_date = false;
        }
        else
        {
            let start_seconds = parseInt(tmp_start_date.getSeconds());
            let end_seconds = parseInt(tmp_end_date.getSeconds());

            if(end_seconds < start_seconds)
            {
                valid_date = false;
            }
        }
    }
  }

  return valid_date;
}


export function calculateOccurenceStatusPerformances(list_performances, is_live_map)
{
    let count_status_perf = {};

    if(list_performances != null && list_performances.length > 0)
    {
      let count_normal = 0;
      let count_critical = 0;
      let count_maintenance = 0;
      let count_unknown = 0;
      for(let i = 0; i < list_performances.length; i++)
      {
        let performance = list_performances[i];
        //filtro anche se sito è null altrimento nella PRogressBar ho valore non coerenti con quelli mostrati in mappa
        if(performance != null && performance.status != null && (!is_live_map || (is_live_map && performance.site != null))){
          if(performance.status.value == "normal")
          {
            count_normal = count_normal + 1;
          }
          else if(performance.status.value == "critical")
          {
            count_critical = count_critical + 1;
          }
          else if(performance.status.value == "maintenance")
          {
            count_maintenance = count_maintenance + 1;
          }
          else if(performance.status.value == "unknown")
          {
            count_unknown = count_unknown + 1;
          }
        }
      }

      count_status_perf = {
        "count_normal": count_normal,
        "count_critical": count_critical,
        "count_maintenance": count_maintenance,
        "count_unknown": count_unknown
      };
    }

    return count_status_perf;
}

export function createDictionariesDropDownComponent(list_components)
{
    let data = [];

    if(list_components != null && list_components.length > 0)
    {
        for(let f = 0; f < list_components.length; f++)
        {
            let comp = list_components[f];
            let measure = "";

            if(comp.type == "double_chart")
            {
                measure = comp.measure.join("/");
            }
            else
            {
                measure = comp.measure;
            }

            let tmp = {
                key: measure, 
                text: measure, 
                value: measure
            }

            data.push(tmp);
        }

        data = data.sort((a,b) => a.text - b.text);
    }

    return data;
}

export function createPatternDropDown(list_value)
{
    let data = [];

    let order_list = list_value.sort(function (a, b) {
        return a.description.toLowerCase().localeCompare(b.description.toLowerCase());
      })

    if(order_list != null && order_list.length > 0)
    {
        for(let f = 0; f < order_list.length; f++)
        {
            let tmp = {
                key: order_list[f]._id, 
                text: order_list[f].description, 
                value: order_list[f].code
            }

            data.push(tmp);
        }
    }

    return data;
}

export function createPatternDropDownUserGroups(list_value)
{
    let data = [];

    let order_list = list_value.sort(function (a, b) {
        return a.description.toLowerCase().localeCompare(b.description.toLowerCase());
      })

    if(order_list != null && order_list.length > 0)
    {
        for(let f = 0; f < order_list.length; f++)
        {
            let description = "[ " + order_list[f].code + " ] " + order_list[f].description;
            
            let tmp = {
                key: order_list[f]._id, 
                text: description, 
                value: order_list[f].code
            }

            data.push(tmp);
        }
    }

    return data;
}

export function createPatternDropdownUser(users) {
    let options = [];

    if(users != null && users.length > 0)
    {
        for(let f = 0; f < users.length; f++)
        {
            let tmp = {
                key: users[f]._id, 
                text: users[f].user_name, 
                value: users[f]._id
            }

            options.push(tmp);
        }
    }

    return options;
  }

export function createPatternDropDownHostname(list_agent) {
    let data = [];
    let order_list = [];
    let have_name_key = false;

    if(list_agent != null && list_agent.length > 0)
    {
        //vedo che chiavi ha per capire come fare il dropdown
        if(list_agent[0].name != null)
        {
            have_name_key = true;
            order_list = list_agent.sort(function (a, b) {
                return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
            })
        }
        else
        {
            order_list = list_agent.sort(function (a, b) {
                return a.hostname.toLowerCase().localeCompare(b.hostname.toLowerCase());
            })  
        }
    }


    if(order_list != null && order_list.length > 0)
    {
        for(let f = 0; f < order_list.length; f++)
        {
            let hostname = have_name_key ? patternHostname(order_list[f].hostname) : order_list[f].hostname;
            let description = have_name_key ? order_list[f].name + " - " + hostname : order_list[f].hostname;
            let tmp = {
                key: hostname, 
                text: description, 
                value: hostname
            }

            data.push(tmp);
        }
    }

    return data;
  }

export function distinctMeasureByIdMeasure(list_measures, available_measures){
    let map = new Map();

    if(available_measures != null && available_measures.length > 0)
    {
        let id_available_measures = available_measures.map((m) => {return m.id});

        if(id_available_measures != null && id_available_measures.length > 0)
        {
            for(let i = 0; i < id_available_measures.length; i++)
            {
                let value = list_measures.find((lm) => {return lm.id == id_available_measures[i]});

                if(value != null)
                {
                    map.set(id_available_measures[i], value)
                }
                else
                {
                    let measure = available_measures.find((am) => {return am.id == id_available_measures[i]});
                    map.set(id_available_measures[i], measure);
                }
            }
        }

    }

    return map;
}

export function patternHostname(hostname) {
    return hostname.split(".").map(Number).join(".");
}

export function createPatternDropDownOnlyCode(list_value)
{
  let option = [];

  if(list_value != null && list_value.length > 0)
  {
    let order_list = list_value.sort();
    for(let r = 0; r < order_list.length; r++)
      {
          let tmp = {
              key: order_list[r], 
              text: order_list[r],
              value: order_list[r]
          }

          option.push(tmp);
      }
  }

  return option;
}

function onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
}

export function createOptionOutput(performances){
    let options = [];

    if(performances != null && performances.length > 0)
    {
        let output = performances.map((p)=>{return p.output});

        if(output != null && output.length > 0)
        {
            let unique = output.filter(onlyUnique);
            if(unique != null && unique.length > 0){
                options = createPatternDropDownOnlyCode(unique);
            }
        }

    }

    return options;
}

export function createPatternDropDownWithIdValue(list_value)
{
  let data = [];

  if(list_value != null && list_value.length > 0)
  {
      for(let f = 0; f < list_value.length; f++)
      {
          let tmp = {
              key: list_value[f]._id, 
              text: list_value[f].description, 
              value: list_value[f]._id
          }

          data.push(tmp);
      }
  }

  return data;
}

export function calculateAvailableSidebar(routes, level)
{
    let available_routes = [];

    if(routes != null && routes.length > 0)
    {
        for(let i = 0; i < routes.length; i++)
        {
            let tmp_route = [];
            if(isNavbarLabelShow(routes[i], level))
            {
                tmp_route.push(routes[i].label);
                if(routes[i].children != null && routes[i].children.length > 0)
                {
                    let filtered_children = routes[i].children.filter((c) => {return isNavbarLabelShow(c, level) ? c : ""});
                    if(filtered_children != null && filtered_children.length > 0)
                    {
                        let name_filtered_children = filtered_children.map((f) =>{return "c_" + f.name});
                        tmp_route = tmp_route.concat(name_filtered_children);
                        
                        for(let y = 0; y < filtered_children.length; y++)
                        {
                            if(filtered_children[y].children != null && filtered_children[y].children.length > 0)
                            {
                                let filtered_children_children = filtered_children[y].children.filter((cc) => {return isNavbarLabelShow(cc, level) ? cc : ""});
                                if(filtered_children_children != null && filtered_children_children.length > 0)
                                {
                                    let name_filtered_children_children = filtered_children_children.map((ff) =>{return "cc_" + ff.name});
                                    tmp_route = tmp_route.concat(name_filtered_children_children);

                                    for(let z = 0; z < filtered_children_children.length; z++)
                                    {
                                        if(filtered_children_children[z].children != null && filtered_children_children[z].children.length > 0)
                                        {
                                            let filtered_children_children_children = filtered_children_children[z].children.filter((ccc) => {if(isNavbarLabelShow(ccc, level)) return ccc});
                                            if(filtered_children_children_children != null && filtered_children_children_children.length > 0)
                                            {
                                                let name_filtered_children_children_children = filtered_children_children_children.map((fff) =>{return "ccc_" + fff.name});
                                                tmp_route = tmp_route.concat(name_filtered_children_children_children);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            available_routes = available_routes.concat(tmp_route);
        }
    }

    return available_routes;
}

export function isNavbarLabelShow(route, level)
{
  let show = false;

  let show_limited = route.calculate == 0 && level != 0;
  let show_all = route.calculate == 1;
  let show_admin = route.calculate == 2 && (level == 5 || level == 6);
  let show_advanced = route.calculate == 3 && (level == 5 || level == 6 || level == 4);
  let show_super_admin = route.calculate == 999999 && (level == 6);

  if(show_limited || show_all || show_admin || show_advanced || show_super_admin)
  {
    show = true;
  }
  
  return show; 
}

export function exportToExcel(data, title)
{
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data);

    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, title + ".xlsx")
}

export function createMapMarker(list_performances){
    let tmp_list_markers = [];
    let group_by_marker = groupMarkerBySite(list_performances);
    if(group_by_marker != null && group_by_marker.size > 0)
    {
        for(let key of group_by_marker.keys()) {
        let tmp_marker =
        {
            "site" : key,
            "performances" : group_by_marker.get(key)
        }

        tmp_list_markers.push(tmp_marker)
        }
    }

    return tmp_list_markers;
}

export function calculateShowColumns(all_col, show_col)
{
  let show_columns = [];
  if(all_col != null && all_col.length > 0)
  {
    let order_all_columns = all_col.sort(function(a, b){  
        return show_col.indexOf(a.accessor) - show_col.indexOf(b.accessor);
    });

    for(let c = 0; c < order_all_columns.length; c++)
    {
      if(show_col.includes(order_all_columns[c].accessor))
      {
        show_columns.push(order_all_columns[c]);
      }
    }
  }
  return show_columns;
}

export function calculateMeasureInternalDataDescription(interpreter, avaiable_measure)
{
    let description = "";
    
    if(interpreter != null && interpreter.code == "NPE")
    {
        description = avaiable_measure.description + 
            "( " + avaiable_measure.internal_data.hostname + " - " + avaiable_measure.internal_data.oid + "." + avaiable_measure.internal_data.oidIndex + " )";
    }
    else
    {
        if(avaiable_measure != null && avaiable_measure.internal_data != null)
        {
            delete avaiable_measure.internal_data.labels.__name__;
            description = avaiable_measure.description + "( " + JSON.stringify(avaiable_measure.internal_data.labels) + " )";
            description = description.replaceAll(",", ", ");
        }
    }
    
    return description;
}

export function calculateTypeSourceUser(user_org_type)
{
    let source_user = 'Unio';

    if(user_org_type == 1)
    {
        source_user = 'NetPOD';
    }

    return source_user;
}

export function createPatternDropDownCodeDescription(list_mux)
{
    let data = [];

    if(list_mux != null && list_mux.length > 0)
    {
        for(let f = 0; f < list_mux.length; f++)
        {
          let mux = list_mux[f]

            let tmp = {
                key: mux.code, 
                text: mux.description, 
                value: mux.code
            }

            data.push(tmp);
        }
    }

    return data;
}