import { PROFILE_DATA, SHOW_LOADER, HIDE_LOADER } from '../actions/pageLoader';

const initialState = {
    data: {},
    loading: false
  };
  
  export default function reducerPageLoader(state = initialState, pageLoader) {
    switch (pageLoader.type) {
      case PROFILE_DATA:
        return {
          ...state,
          data: { ...pageLoader.data }
        };
  
      case SHOW_LOADER:
          return { ...state, loading: true };
  
      case HIDE_LOADER:
        return { ...state, loading: false };
  
      default:
        return state;
    }
  };