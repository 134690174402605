import React, { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton } from 'components/common/Toast';

import 'react-toastify/dist/ReactToastify.min.css';
import 'semantic-ui-css/semantic.min.css'
import 'components/style/customizeDark.scss'
import 'components/style/max_width_576.scss'
import 'components/style/max_width_1200.scss'
import 'components/style/generic.scss'
import 'react-datepicker/dist/react-datepicker.css';

const MainLayout = lazy(() => import ('./MainLayout'));

const CardLogin = lazy(() => import ('components/authentication/card/Login'));
const ForgetPassword = lazy(() => import ('components/authentication/card/ForgetPassword'));
const Error404 = lazy(() => import ('components/errors/Error404'));
const FullPageLoader = lazy(() => import ('components/common/full-page-loader/FullPageLoader'));
const ErrorLayout = lazy(() => import ('./ErrorLayout'));

//const InitialDashboard = lazy(() => import ('components/dashboards/default/InitialDashboard'));

const ReportDownload = lazy(() => import ('components/report/ReportDownload'));

const PerformancesLive = lazy(() => import ('components/performances/live/PerformancesLive'));
const PerformancesStatistics = lazy(() => import ('components/performances/statistics/PerformancesStatistics'));
const PerformancesStatus = lazy(() => import ('components/performances/status/PerformancesStatus'));
const PerformancesTrend = lazy(() => import ('components/performances/trend/PerformancesTrend'));

const MonitoringDevices = lazy(() => import ('components/devices/MonitoringDevices'));
const DisplayedDevicesMeasures = lazy(() => import ('components/devices/DisplayedDevicesMeasures'));

const Exclusions = lazy(() => import ('components/sla/Exclusions'));
const Inclusions = lazy(() => import ('components/sla/Inclusions'));

const SettingsUser = lazy(() => import ('components/pages/user/settings/SettingsUser'));
const SettingsFilters = lazy(() => import ('components/pages/user/settings/SettingsFilters'));
const UsersCreation = lazy(() => import ('components/user/UsersCreation'));

const AdvancedSettingsEdges = lazy(() => import ('components/edges/AdvancedSettingsEdges'));
const AdvancedSettingsAddEdge = lazy(() => import ('components/edges/AdvancedSettingsAddEdge'));
const AdvancedSettingsEditEdge = lazy(() => import ('components/edges/AdvancedSettingsEditEdge'));
const AdvancedSettingsCloneEdge = lazy(() => import ('components/edges/AdvancedSettingsCloneEdge'));

const AdvancedSettingsReport = lazy(() => import ('components/report/AdvancedSettingsReport'));
const ReportLinkGroups = lazy(() => import ('components/report/ReportLinkGroups'));

const AdvancedSettingsAddSla = lazy(() => import ('components/sla/AdvancedSettingsAddSla'));
const AdvancedSettingsAddService = lazy(() => import ('components/sla/AdvancedSettingsAddService'));
const AdvancedSettingsEditSla = lazy(() => import ('components/sla/AdvancedSettingsEditSla'));
const AdvancedSettingsCloneSla = lazy(() => import ('components/sla/AdvancedSettingsCloneSla'));
const AdvancedSettingsEditService = lazy(() => import ('components/sla/AdvancedSettingsEditService'));
const AdvancedSettingsCloneService = lazy(() => import ('components/sla/AdvancedSettingsCloneService'));
const AdvancedSettingsSlas = lazy(() => import ('components/sla/AdvancedSettingsSlas'));
const AdvancedSettingsServices = lazy(() => import ('components/sla/AdvancedSettingsServices'));
const AdvancedSettingsAddMux = lazy(() => import ('components/mux/AdvancedSettingsAddMux'));
const AdvancedSettingsEditMux = lazy(() => import ('components/mux/AdvancedSettingsEditMux'));
const AdvancedSettingsCloneMux = lazy(() => import ('components/mux/AdvancedSettingsCloneMux'));
const AdvancedSettingsMux = lazy(() => import ('components/mux/AdvancedSettingsMux'));

const SlaLinkByPerformance = lazy(() => import ('components/sla/SlaLinkByPerformance'));
const SlaLinkToPerformance = lazy(() => import ('components/sla/SlaLinkToPerformance'));
const ServiceLinkGroups = lazy(() => import ('components/sla/ServiceLinkGroups'));
const SlaServicesMultiEdit = lazy(() => import ('components/sla/SlaServicesMultiEdit'));

const PerformancesSettings = lazy(() => import ('components/performances/PerformancesSettings'));
const EditPerformancesSettings = lazy(() => import ('components/performances/EditPerformancesSettings'));
const PerformancesMultiEdit = lazy(() => import ('components/performances/PerformancesMultiEdit'));

const AdvancedSettingsFilters = lazy(() => import ('components/performances/AdvancedSettingsFilters'));
const AdvancedSettingsGroups = lazy(() => import ('components/user/AdvancedSettingsGroups'));
const AdvancedSettingsAddGroup = lazy(() => import ('components/user/AdvancedSettingsAddGroup'));
const AdvancedSettingsEditGroup = lazy(() => import ('components/user/AdvancedSettingsEditGroup'));
const AdvancedSettingsHierarchyGroup = lazy(() => import ('components/user/AdvancedSettingsHierarchyGroup'));
const UserLinkGroups = lazy(() => import ('components/user/UserLinkGroups'));
const DeviceLinkGroups = lazy(() => import ('components/devices/DeviceLinkGroups'));

const SitesSettings = lazy(() => import ('components/sites/SitesSettings'));
const EditSitesSettings = lazy(() => import ('components/sites/EditSitesSettings'));
const SitesMultiEdit = lazy(() => import ('components/sites/SitesMultiEdit'));

const ListInterpreters = lazy(() => import ('components/devices/ListInterpreters'));
const AddInterpreter = lazy(() => import ('components/devices/AddInterpreter'));
const EditInterpreter = lazy(() => import ('components/devices/EditInterpreter'));
const CloneInterpreter = lazy(() => import ('components/devices/CloneInterpreter'));
const AdvancedSettingsDevices = lazy(() => import ('components/devices/AdvancedSettingsDevices'));
const AdvancedSettingsAddDevice = lazy(() => import ('components/devices/AdvancedSettingsAddDevice'));
const AdvancedSettingsEditDevice = lazy(() => import ('components/devices/AdvancedSettingsEditDevice'));
const AdvancedSettingsCloneDevice = lazy(() => import ('components/devices/AdvancedSettingsCloneDevice'));
const ConfigurationDevicesMeasures = lazy(() => import ('components/devices/ConfigurationDevicesMeasures'));
const DevicesMultiEdit = lazy(() => import ('components/devices/DevicesMultiEdit'));

const UsersSidebar = lazy(() => import ('components/user/UsersSidebar'));
const DetailUserSidebar = lazy(() => import ('components/user/DetailUserSidebar'));
const UserDictionary = lazy(() => import ('components/user/UserDictionary'));
const UserAddDictionary = lazy(() => import ('components/user/UserAddDictionary'));
const UserEditDictionary = lazy(() => import ('components/user/UserEditDictionary'));
const AdvancedSettingsUsers = lazy(() => import ('components/user/AdvancedSettingsUsers'));

const About = lazy(() => import ('components/app/About'));
const SystemInfo = lazy(() => import ('components/app/SystemInfo'));

const DataImport = lazy(() => import ('components/settings_ant/DataImport'));
const UnioSettings = lazy(() => import ('components/settings_ant/UnioSettings'));


const Layout = () => { 
  const ProtectedRoute  = () => {
    if (!JSON.parse(localStorage.getItem('authenticated'))) {
      return (<Navigate  to="/authentication/card/login" replace />)
      } 
      return <MainLayout /> ;
  };

  return (
    <>
      <Routes>
        <Route element={<ErrorLayout />}>
          <Route path="errors/404" element={<Error404 />} />
        </Route>
        {/*- ------------- Authentication ---------------------------  */}

        {/*- ------------- Card ---------------------------  */}
        {<Route element={ <ProtectedRoute /> } >
          {/*Dashboard*/}
          {/*<Route path="workspace/dashboard" element={<InitialDashboard />} />*/}

          {/*Performances*/}
          <Route path="performances/live" element={<PerformancesLive />} />
          <Route path="performances/statistics" element={<PerformancesStatistics />} />
          <Route path="performances/status" element={<PerformancesStatus />} />
          <Route path="performances/trend" element={<PerformancesTrend />} />

          {/*Devices*/}
          <Route path="devices" element={<MonitoringDevices />} />
          <Route path="devices/measures/:deviceCODE" element={<DisplayedDevicesMeasures />} />

          {/*SLA*/}
          <Route path="sla/exclusions" element={<Exclusions />} />
          <Route path="sla/inclusions" element={<Inclusions />} />

          {/*Measures*/}
          {/*<Route path="/measures/dashboard" element={<MeasuresDashboard />} />*/}

          {/*Customize */}
          <Route path="customize/user" element={<SettingsUser />} />
          <Route path="customize/filters" element={<SettingsFilters />} />

          {/*Report */}
          <Route path="report/download" element={<ReportDownload />} />

          {/*Advanced Settings */}
          <Route path="advanced_settings/edges" element={<AdvancedSettingsEdges />} />
          <Route path="advanced_settings/edges/add" element={<AdvancedSettingsAddEdge />} />
          <Route path="advanced_settings/edges/edit/:edgeCODE" element={<AdvancedSettingsEditEdge />} />
          <Route path="advanced_settings/edges/clone/:edgeCODE" element={<AdvancedSettingsCloneEdge />} />

          <Route path="advanced_settings/interpreters" element={<ListInterpreters />} />
          <Route path="advanced_settings/interpreters/add" element={<AddInterpreter />} />
          <Route path="advanced_settings/interpreters/edit/:interpreterCODE" element={<EditInterpreter />} />
          <Route path="advanced_settings/interpreters/clone/:interpreterCODE" element={<CloneInterpreter />} />

          <Route path="advanced_settings/devices" element={<AdvancedSettingsDevices />} />
          <Route path="advanced_settings/devices/add" element={<AdvancedSettingsAddDevice />} />
          <Route path="advanced_settings/devices/measures/:deviceCODE" element={<ConfigurationDevicesMeasures />} />
          <Route path="advanced_settings/devices/edit/:deviceCODE" element={<AdvancedSettingsEditDevice />} />
          <Route path="advanced_settings/devices/clone/:deviceCODE" element={<AdvancedSettingsCloneDevice />} />
          
          <Route path="advanced_settings/devices_multi_edit" element={<DevicesMultiEdit />} />
          <Route path="advanced_settings/device_link_groups" element={<DeviceLinkGroups />} />

          <Route path="advanced_settings/groups_filters" element={<AdvancedSettingsFilters />} />
          <Route path="advanced_settings/groups_settings" element={<AdvancedSettingsGroups />} />
          <Route path="advanced_settings/groups_settings/add" element={<AdvancedSettingsAddGroup />} />
          <Route path="advanced_settings/groups_settings/edit" element={<AdvancedSettingsEditGroup />} />
          <Route path="advanced_settings/groups_settings/hierarchy" element={<AdvancedSettingsHierarchyGroup />} />
            

          <Route path="advanced_settings/performances_settings" element={<PerformancesSettings />} />
          <Route path="advanced_settings/performances_settings/edit/:performanceID" element={<EditPerformancesSettings />} />
          <Route path="advanced_settings/performances_multi_edit" element={<PerformancesMultiEdit />} />

          <Route path="advanced_settings/report" element={<AdvancedSettingsReport />} />
          <Route path="advanced_settings/report_link_group" element={<ReportLinkGroups />} />

          <Route path="advanced_settings/sites_settings" element={<SitesSettings />} />
          <Route path="advanced_settings/sites_settings/edit/:siteID" element={<EditSitesSettings />} />
          <Route path="advanced_settings/sites_multi_edit" element={<SitesMultiEdit />} />

          <Route path="advanced_settings/sla" element={<AdvancedSettingsSlas />} />
          <Route path="advanced_settings/service" element={<AdvancedSettingsServices />} />
          <Route path="advanced_settings/sla/add" element={<AdvancedSettingsAddSla />} />
          <Route path="advanced_settings/service/add" element={<AdvancedSettingsAddService />} />
          <Route path="advanced_settings/sla/edit/:slaCODE" element={<AdvancedSettingsEditSla />} />
          <Route path="advanced_settings/sla/clone/:slaCODE" element={<AdvancedSettingsCloneSla />} />
          <Route path="advanced_settings/service/edit/:serviceCODE" element={<AdvancedSettingsEditService />} />
          <Route path="advanced_settings/service/clone/:serviceCODE" element={<AdvancedSettingsCloneService />} />

          <Route path="advanced_settings/mux" element={<AdvancedSettingsMux />} />
          <Route path="advanced_settings/mux/add" element={<AdvancedSettingsAddMux />} />
          <Route path="advanced_settings/mux/edit/:muxCODE" element={<AdvancedSettingsEditMux />} />
          <Route path="advanced_settings/mux/clone/:muxCODE" element={<AdvancedSettingsCloneMux />} />

          <Route path="advanced_settings/link_by_performance" element={<SlaLinkByPerformance />} />
          <Route path="advanced_settings/link_to_performance" element={<SlaLinkToPerformance />} />
          <Route path="advanced_settings/service_link_groups" element={<ServiceLinkGroups />} />            
          <Route path="advanced_settings/sla_service_multi_edit" element={<SlaServicesMultiEdit />} />

          <Route path="advanced_settings/user_settings" element={<AdvancedSettingsUsers />} />
          <Route path="advanced_settings/user_creation" element={<UsersCreation />} />
          <Route path="advanced_settings/user_link_groups" element={<UserLinkGroups />} />
          <Route path="advanced_settings/user_dictionary" element={<UserDictionary/>} />
          <Route path="advanced_settings/user_dictionary/add" element={<UserAddDictionary/>} />
          <Route path="advanced_settings/user_dictionary/edit/:dictionaryCODE" element={<UserEditDictionary/>} />
          <Route path="advanced_settings/user_sidebar" element={<UsersSidebar />} />
          <Route path="advanced_settings/user_sidebar/:userID" element={<DetailUserSidebar />} />
          

          {/*About */}
          <Route path="about" element={<About />} />
          <Route path="system_info" element={<SystemInfo />} />

          {/*ANT */}
          <Route path="ant/data_import" element={<DataImport />} />
          <Route path="ant/settings" element={<UnioSettings />} />
        </Route>}

        <Route path="/" element={<CardLogin />} />
        <Route path="authentication/card/login" element={<CardLogin />} />
        <Route path="authentication/card/forgot_password" element={<ForgetPassword />} />

        {/* <Navigate to="/errors/404" /> */}
        <Route path="*" element={<Navigate to="/errors/404" replace />} />
      </Routes>

      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.TOP_RIGHT}
      />
      <FullPageLoader />
    </>
  );
};

export default Layout;
