import AGRest  from "../agcustom/common/AGRest"
import {formatErrorResponseMessage, formatResponseMessage }   from "../agcustom/common/AGFunctions"
import {toast} from 'react-toastify';
import { updateDictClassification, updateDictRegion, updateDictProvince, updateDictTags, updateDictOC, updateDictSite, updateDictStatus } from 'actions/dictionaries';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export function receiveLogin() {
    return {
        type: LOGIN_SUCCESS
    };
}

export function loginError(payload) {
    return {
        type: LOGIN_FAILURE,
        payload,
    };
}

function requestLogout() {
    return {
        type: LOGOUT_REQUEST,
    };
}

export function receiveLogout() {
    return {
        type: LOGOUT_SUCCESS,
        undefined,
    };
}

// Logs the user out
export function logoutUser() {
    return (dispatch) => {
        dispatch(requestLogout());
            localStorage.setItem('isLogout', true);

            AGRest.logout().then((response)=>{
                if(response.response.ok){
                    localStorage.removeItem('authenticated');
                    localStorage.removeItem('access_token');
                    localStorage.removeItem('refresh_token');
                    localStorage.removeItem('server_rest');
                    localStorage.removeItem('user_logged');
                    localStorage.removeItem('user_id');
                    localStorage.removeItem('is_change_password');
                    localStorage.removeItem('pattern_date');
                    localStorage.removeItem('timezone');
                    localStorage.removeItem('groups_user_read');
                    localStorage.removeItem('groups_user_write');
                    localStorage.removeItem('user_default_filter');
                    localStorage.removeItem('list_performances');
                    localStorage.removeItem('first_day_week');
                    localStorage.removeItem('start_max_zoom');
                    localStorage.removeItem('user_avatar');
                    localStorage.removeItem('expired_token_sec');
                    localStorage.removeItem('record_for_table_page');
                    localStorage.removeItem('user_sidebar');
                    localStorage.removeItem('user_level');
                    localStorage.removeItem('homepage');
                    localStorage.removeItem('chunck_value');
                    
                    dispatch(receiveLogout());

                    dispatch(updateDictClassification([]));
                    dispatch(updateDictRegion([]));
                    dispatch(updateDictProvince([]));
                    dispatch(updateDictTags([]));
                    dispatch(updateDictOC([]));
                    dispatch(updateDictSite([]))
                    dispatch(updateDictStatus([]));

                    toast.success("Logout successfully", { theme: 'colored' });
                }
                else {
                    if(response.response.status==401){
                        localStorage.removeItem('authenticated');
                        localStorage.removeItem('access_token');
                        localStorage.removeItem('refresh_token');
                        localStorage.removeItem('server_rest');
                        localStorage.removeItem('user_logged');
                        localStorage.removeItem('user_id');
                        localStorage.removeItem('is_change_password');
                        localStorage.removeItem('pattern_date');
                        localStorage.removeItem('timezone');
                        localStorage.removeItem('groups_user_read');
                        localStorage.removeItem('groups_user_write');
                        localStorage.removeItem('user_default_filter');
                        localStorage.removeItem('list_performances');
                        localStorage.removeItem('first_day_week');
                        localStorage.removeItem('start_max_zoom');
                        localStorage.removeItem('user_avatar');
                        localStorage.removeItem('record_for_table_page');
                        localStorage.removeItem('user_sidebar');
                        localStorage.removeItem('user_level');
                        localStorage.removeItem('homepage');
                        localStorage.removeItem('chunck_value');
                    }
                    else {
                        toast.error(formatResponseMessage(response, "Logout User"), { theme: 'colored' });
                    }
                }
            }).catch((error)=>{
                toast.error(formatErrorResponseMessage(error, "Logout User"), { theme: 'colored' });
        })
    };
}