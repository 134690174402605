import { UPDATE_DICT_CLASSIFICATION, UPDATE_DICT_TAGS, UPDATE_DICT_PROVINCE,
         UPDATE_DICT_STATUS, UPDATE_DICT_REGION, UPDATE_DICT_SITE, UPDATE_DICT_OC
} from '../actions/dictionaries';

const initialState = {
  list_classification: [],
  list_tags: [],
  list_status: [],
  list_region: [],
  list_site: [],
  list_operation_center: [],
  list_province: [],
};

export default function reducerDictionaries(state = initialState, dictionaries) {
  switch (dictionaries.type) {
    case UPDATE_DICT_CLASSIFICATION:
      return {
        ...state,
        list_classification: dictionaries.list_classification,
      };
    case UPDATE_DICT_TAGS:
        return {
            ...state,
            list_tags: dictionaries.list_tags,
        };
    case UPDATE_DICT_STATUS:
        return {
            ...state,
            list_status: dictionaries.list_status,
        };
    case UPDATE_DICT_REGION:
        return {
            ...state,
            list_region: dictionaries.list_region,
        };
    case UPDATE_DICT_SITE:
        return {
            ...state,
            list_site: dictionaries.list_site,
        };
    case UPDATE_DICT_OC:
        return {
            ...state,
            list_operation_center: dictionaries.list_operation_center,
        };
    case UPDATE_DICT_PROVINCE:
        return {
            ...state,
            list_province: dictionaries.list_province,
        };
    default:
      return state;
  }
}
