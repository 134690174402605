import AGRest  from "../agcustom/common/AGRest"
import {formatErrorResponseMessage, formatResponseMessage }   from "../agcustom/common/AGFunctions"
import {toast} from 'react-toastify';
import { logoutUser } from './user';

export const UPDATE_DICT_CLASSIFICATION = 'UPDATE_DICT_CLASSIFICATION';
export const UPDATE_DICT_TAGS = 'UPDATE_DICT_TAGS';
export const UPDATE_DICT_STATUS = 'UPDATE_DICT_STATUS';
export const UPDATE_DICT_REGION = 'UPDATE_DICT_REGION';
export const UPDATE_DICT_SITE = 'UPDATE_DICT_SITE';
export const UPDATE_DICT_OC = 'UPDATE_DICT_OC';
export const UPDATE_DICT_PROVINCE = 'UPDATE_DICT_PROVINCE';

export function retriveDictionary(){
    return (dispatch) => {
      AGRest.getDictionaries("perf_classification,perf_region,perf_province,perf_tags,perf_site_description,perf_operation_center").then((response)=>{
        if(response.response.ok){
          let all_dictionary = response.data.results;

          let list_classification = all_dictionary.find((c) => {return c.dict_type == "perf_classification"})
          let list_region = all_dictionary.find((c) => {return c.dict_type == "perf_region"})
          let list_province = all_dictionary.find((c) => {return c.dict_type == "perf_province"})
          let list_tags = all_dictionary.find((c) => {return c.dict_type == "perf_tags"})
          let list_operation_center = all_dictionary.find((c) => {return c.dict_type == "perf_operation_center"})
          let list_site = all_dictionary.find((c) => {return c.dict_type == "perf_site_description"})
          let list_status = ["normal", "critical", "maintenance", "unknown"]


          dispatch(updateDictClassification(list_classification != null ? list_classification.dict_values : []));
          dispatch(updateDictRegion(list_region != null ? list_region.dict_values : []));
          dispatch(updateDictProvince(list_province != null ? list_province.dict_values : []));
          dispatch(updateDictTags(list_tags != null ? list_tags.dict_values : []));
          dispatch(updateDictOC(list_operation_center != null ? list_operation_center.dict_values : []));
          dispatch(updateDictSite(list_site != null ? list_site.dict_values : []))
          dispatch(updateDictStatus(list_status));

        } else {
          if(response.response.status==401){
            dispatch(logoutUser())
          } else {
            toast.error(formatResponseMessage(response, "Error retrieve dictionaries"), { theme: 'colored' });
          }
        }
      }).catch((error)=>{
        toast.error(formatErrorResponseMessage(error, "Error retrieve dictionaries"), { theme: 'colored' });
      })
    }
}
  
  export function updateDictClassification(list_classification){
    return {
      type: UPDATE_DICT_CLASSIFICATION,
      list_classification
    };
  };

  export function updateDictTags(list_tags){
    return {
      type: UPDATE_DICT_TAGS,
      list_tags
    };
  };
  
  export function updateDictStatus(list_status){
    return {
      type: UPDATE_DICT_STATUS,
      list_status
    };
  };
  
  export function updateDictRegion(list_region){
    return {
      type: UPDATE_DICT_REGION,
      list_region
    };
  };

  export function updateDictSite(list_site){
    return {
      type: UPDATE_DICT_SITE,
      list_site
    };
  };

  export function updateDictOC(list_operation_center){
    return {
      type: UPDATE_DICT_OC,
      list_operation_center
    };
  };

  export function updateDictProvince(list_province){
    return {
      type: UPDATE_DICT_PROVINCE,
      list_province
    };
  };