import React, { Suspense } from 'react';
import { createRoot }  from 'react-dom/client';
import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit'
import { Provider } from 'react-redux'
import auth from 'reducers/auth';
import dictionaries from 'reducers/dictionaries'; 
import pageLoader from 'reducers/pageLoader';
import infiniteLoop from 'assets/img/animated-icons/infinite-loop.json';
import Lottie from "lottie-react";
import { persistStore, persistReducer,  } from 'redux-persist'
import { AsyncStorage } from 'AsyncStorage';
import { PersistGate } from 'redux-persist/integration/react'
import thunk from 'redux-thunk';

import Main from './Main';
import App from './App';

import './i18next'

const persistConfig = {
  key: 'main',
  storage: AsyncStorage,
}

const userReducer = combineReducers({
  auth,
  dictionaries,
  pageLoader,
})

const persistedReducer = persistReducer(persistConfig, userReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk]
});

const persistor = persistStore(store);

const rootElement = document.getElementById("main");
const root = createRoot(rootElement);

root.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Main>
          <Suspense fallback={<div style={{position: "fixed", top: 0, left: 0, width: "100%", height: "100%", backgroundColor: "#F8F8F8AD"}}>
                                <div style={{left: "50%", top: "50%", transform: 'translate(-50%, -50%)', position: 'absolute', zIndex : 1000}}>
                                  <Lottie animationData={infiniteLoop} style={{height: '120px', width: '120px'}}/>
                                </div>
                              </div>}>
            <App />
          </Suspense>
        </Main>
      </PersistGate>
    </Provider>
);